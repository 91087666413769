<template>
  <svg
    :class="[
      'animation-transformation',
      {
        'rotate-left': rotation === 'left',
        'rotate-right': rotation === 'right',
        'rotate-top': rotation === 'top',
      },
    ]"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.41 8.57999L12 13.17L16.59 8.57999L18 9.99999L12 16L6 9.99999L7.41 8.57999Z"
      fill="var(--settings-registration-current-event-button-background)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Chevron',
  props: {
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
    rotation: {
      type: String,
      default: 'bottom',
    },
  },
};
</script>

<style lang="scss" scoped>
.animation-transformation {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.rotate-left {
  transform: rotate(90deg);
}
.rotate-right {
  transform: rotate(270deg);
}
.rotate-top {
  transform: rotate(180deg);
}
</style>
