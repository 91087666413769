<template>
  <div v-if="popup" class="mainContainer">
    <section v-click-outside="closePopup" class="centerDiv">
      <div class="d-flex">
        <div class="my-auto">Favorites</div>
        <div class="ml-auto cursor-pointer" @click="closePopup">
          <CloseIcon />
        </div>
      </div>
      <section class="my-2">
        <div class="d-flex">
          <div class="mx-auto">
            <ImageComponent class="imgSize" :path="favorite.image" />
          </div>
        </div>
        <div class="d-flex">
          <div class="mx-auto">
            {{ favorite.name }}
          </div>
        </div>
      </section>
      <section class="my-4">
        {{ "Are you sure to remove " + favorite.name }}
      </section>
      <div class="d-flex">
        <div class="ml-auto my-auto mr-2 cursor-pointer" @click="closePopup">
          {{ "Cancel" }}
        </div>
        <div class="ml-2 my-auto cursor-pointer favoritesButton" @click="confirmPopup">
          {{ "Remove" }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ImageComponent from "@/components/base-components/ImageComponent";
import CloseIcon from "@/components/icons/Close";

export default {
  name: "FavoritesPopup",
  components: {
    ImageComponent,
    CloseIcon,
  },
  props: {
    popup: {
      type: Boolean,
      required: true,
    },
    favorite: {
      type: Object,
      required: true,
    },
    search: {
      type: Boolean,
      required: false,
      value: false
    },
  },
  data() {
  return {
    searchActivationCount: 0
  };
},
  methods: {
    closePopup() {
    if (this.search && this.searchActivationCount === 1) {
      this.$emit("closePopup");
    } else if(!this.search) {
      this.$emit("closePopup");
    }
    else
    {
      this.searchActivationCount++;
    }
  },
    confirmPopup() {
      this.$emit("confirmPopup");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/colors";

@import "src/assets/css/mixins";

.cursor-pointer {
  cursor: pointer;
}
.mainContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
}
.imgSize{
  max-height: 200px;
  max-width: 200px;
}
.centerDiv {
  margin: 70px auto;
  padding: 20px;
  background: $secondary-background;
  border-radius: 5px;
  width: 40%;
  position: relative;
  transition: all 1s ease-in-out;
  @include media-max(md) {
  width: 80%;
  top: 20vh;
  }
}

.favoritesButton {
  background: #e41d34;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5px 10px;
  color: white;
}
</style>
