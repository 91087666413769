var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',{class:[
    'animation-transformation',
    {
      'rotate-left': _vm.rotation === 'left',
      'rotate-right': _vm.rotation === 'right',
      'rotate-top': _vm.rotation === 'top',
    },
  ],attrs:{"width":_vm.width,"height":_vm.height,"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7.41 8.57999L12 13.17L16.59 8.57999L18 9.99999L12 16L6 9.99999L7.41 8.57999Z","fill":"var(--settings-registration-current-event-button-background)"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }