<template>
  <div class="d-flex">
    <svg
      class="m-auto"
      :width="width"
      :height="height"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.6667 10.6833L29.3167 8.33325L20 17.6499L10.6834 8.33325L8.33337 10.6833L17.65 19.9999L8.33337 29.3166L10.6834 31.6666L20 22.3499L29.3167 31.6666L31.6667 29.3166L22.35 19.9999L31.6667 10.6833Z"
        :fill="color"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "CloseIcon",
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 30,
    },
    color: {
      type: String,
      default: "var(--settings-registration-current-event-button-background)",
    },
  },
};
</script>
